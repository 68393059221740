import { render } from "./TenantList.vue?vue&type=template&id=8300f004&scoped=true"
import script from "./TenantList.vue?vue&type=script&lang=js"
export * from "./TenantList.vue?vue&type=script&lang=js"
script.render = render
script.__scopeId = "data-v-8300f004"
/* hot reload */
if (module.hot) {
  script.__hmrId = "8300f004"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('8300f004', script)) {
    api.reload('8300f004', script)
  }
  
  module.hot.accept("./TenantList.vue?vue&type=template&id=8300f004&scoped=true", () => {
    api.rerender('8300f004', render)
  })

}

script.__file = "src/components/login/TenantList.vue"

export default script